import logo from './logo.svg';
import './App.css';

const mirrors = [
  'https://roobet.party',
]

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logo" alt="logo" />
        <h1>Roobet.com Official Mirror List</h1>
        <h2>Can't access Roobet? Try one of these mirrors below!</h2>
      </header>
      <div className="mirrors">
        {
          mirrors.map((mirror) => {
            return (
              <div className="mirror" key={mirror}>
                <a href={mirror}>{capitalizeFirstLetter(mirror.replace('https://',''))}</a>
              </div>
            )
          })
        }
        <div className="mirror">
          More coming soon..
        </div>
      </div>
      <div className="description">
        <h3>What are mirrors?</h3>
        <p>Mirrors are helpful to prevent network overload and or maintain high availability. Use a mirror if you're having issues connecting to Roobet. TLDR; we love ourselves so much we've made clones.</p>
      </div>
    </div>
  );
}

export default App;
